<template>
    <b-modal :ref="modalRef" hide-header hide-footer class="text-center" centered size="sm" @hidden="destroyModal">
        <div class="text-center">
            <p>{{ message }}</p>
            <b-button variant="success" size="sm" @click="applyCallback">Confirmer</b-button>
            <b-button variant="danger" size="sm" @click="hideModal">Annuler</b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin from '@/mixin/modalMixin';

    export default {
        name: "ModalConfirm",
        mixins: [modalMixin],
        data: () => ({
            modalRef: 'alertConfirmModal'
        }),
        props: {
            message: {
                type: String,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        methods: {
            applyCallback() {
                this.callback();
                this.hideModal();
            }
        }
    }
</script>